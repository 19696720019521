import {environment} from "../../../environments/environment";

export const USER_INFO_URL = environment.apiUrl + `/user_info`;
export const DICTIONARY_URL = environment.apiUrl + `/dictionary`;
export const ACCESSES_URL = environment.apiUrl + '/my_accesses/';
export const NEWS_URL = environment.apiUrl + '/news';
export const ORDERS_URL = environment.apiUrl + '/orders';
export const BOOKSHOP_URL = environment.bookShopApiUrl + 'orders';
export const EVENTS_URL = environment.apiUrl + '/events';
export const CALENDAR_EVENTS_URL = environment.apiUrl  + '/calendarEvents';
export const PROFILE_SETTINGS_URL = environment.apiUrl + `/settings`;
export const CLUBS_FORM_URL = environment.apiUrl + `/club_form`;
export const MESSAGES_URL = environment.apiUrl + '/messages';
export const ANNOUNCEMENT_URL = environment.apiUrl + '/my_accesses/announcement';
