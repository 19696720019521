import {createAction, props} from "@ngrx/store";
import { CalendarEventModel, EventModel, EventsPagingMetaData } from "../../../models/interfaces/events-model";

export const initEvents = createAction('[Events] Init Events');

export const initCalendarEvents = createAction('[Events] Init Calendar Events');

export const setEvents = createAction('[Events] Set Events', props<{ eventsData: EventModel[] }>());

export const setEventsPagingMetadata = createAction('[Events] Set Events Paging MetaData', props<{ metaData: EventsPagingMetaData | null }>());

export const resetEventsPagingMetadata = createAction('[Events] Reset Events Paging MetaData');

export const resetEvents = createAction('[Events] Reset Events');

export const loadCalendarEventsDataChunk = createAction('[Events] Load Calendar Events Data Chunk', props<{ dateFrom: Date, dateTo: Date }>());

export const addCalendarEvent = createAction('[Events] Add Calendar Event to Store', props<{ eventsData: CalendarEventModel[] }>());

export const loadAndOverrideAllEventsData = createAction('[Events] Load And Override All Events Data', props<{ dateFrom: Date, dateTo: Date }>());

export const loadAndAddEventsDataChunk = createAction('[Events] Load And Add Events Data Chunk', props<{ dateFrom: Date, dateTo?: Date, page?: number }>());
