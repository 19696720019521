import {createFeatureSelector, createSelector} from "@ngrx/store";
import {userFeatureKey, UserState} from "../reducers/user.reducer";
import {AppState} from "../reducers";
import {UserModel} from "../../../models/interfaces/user-model";
import {DictionaryModel} from "../../../models/interfaces/dictionary-model";
import { NewsModel } from "../../../models/interfaces/news-model";
import { NewsState, newsFeatureKey } from "../reducers/news.reducer";
import { NewsMessageDetailsModel } from "../../../models/interfaces/news-message-details.model";
import { TeacherMessageDetailsModel } from "../../../models/interfaces/teacher-message.model";
import { EventsState, eventsFeatureKey } from "../reducers/events.reducer";
import { CalendarEventModel, EventModel, EventsPagingMetaData } from "../../../models/interfaces/events-model";
import {NotificationModel} from "../../../models/interfaces/notification-model";

const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

const selectNewsState = createFeatureSelector<NewsState>(newsFeatureKey);

const selectEventState = createFeatureSelector<EventsState>(eventsFeatureKey);

export const selectUser = createSelector<AppState, UserState, UserModel | null>(selectUserState, ({user}) => user);

export const selectLoggedOut = createSelector<AppState, UserState, boolean>(
    selectUserState,
    ({isLoggedOut}) => isLoggedOut,
)

export const selectDictionary = createSelector<AppState, UserState, DictionaryModel | null>(selectUserState, ({dictionary}) => dictionary);

export const selectNotification = createSelector<AppState, UserState, NotificationModel[]>(selectUserState, ({notifications}) => notifications);

export const selectIsLoading = createSelector<AppState, UserState, boolean>(selectUserState, ({isLoading}) => isLoading);

export const selectNews = createSelector<AppState, NewsState, NewsModel[] | null>(selectNewsState, ({ newsList }) => newsList);

export const selectTeacherMessages = createSelector<AppState, NewsState, TeacherMessageDetailsModel[] | null>(selectNewsState, ({ teacherMessages }) => teacherMessages);

export const selectRecentNewsDetails = createSelector<AppState, NewsState, NewsMessageDetailsModel | null>(selectNewsState, ({ recentNewsDetails }) => recentNewsDetails);

 export const selectEvents = createSelector<AppState, EventsState, EventModel[] | null>(selectEventState, ({ eventsList }) => eventsList);

 export const selectEventsPagingMetaData = createSelector<AppState, EventsState, EventsPagingMetaData | null>(selectEventState, ({ eventsPagingMetaData }) => eventsPagingMetaData);

 export const selectCalendarEvents = createSelector<AppState, EventsState, CalendarEventModel[] | null>(selectEventState, ({ calendarEventsList }) => calendarEventsList);
