import {createReducer, on} from "@ngrx/store";
import {EventsActions} from "../actions";
import { CalendarEventModel, EventModel, EventsPagingMetaData } from "../../../models/interfaces/events-model";

export const eventsFeatureKey = 'events';

export interface EventsState {
    calendarEventsList: CalendarEventModel[] | null;
    eventsList: EventModel[] | null;
    eventsPagingMetaData: EventsPagingMetaData | null;
}

export const initialState: EventsState = {
    calendarEventsList: null,
    eventsList: null,
    eventsPagingMetaData: null
}

export const eventsReducer = createReducer(
    initialState,
    on(EventsActions.setEvents, (state, { eventsData }) => ({
        ...state,
        eventsList:  [...eventsData ],
    })),
    on(EventsActions.resetEvents, (state) => ({
        ...state,
        eventsList:  null,
    })),
    on(EventsActions.setEventsPagingMetadata, (state, { metaData }) => ({
        ...state,
        eventsPagingMetaData: metaData,
    })),
    on(EventsActions.resetEventsPagingMetadata, (state) => ({
        ...state,
        eventsPagingMetaData:  null,
    })),
    on(EventsActions.addCalendarEvent, (state, { eventsData }) => ({
        ...state,
        calendarEventsList: [
            ...(state.calendarEventsList ?? []),
            ...eventsData
        ],
    })),
)
